body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(209, 209, 209);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  letter-spacing: -3px;
  font-size: 4em;
  color:rgb(255, 255, 255);
}

.showstock {
  width: 50%;
  margin: auto;
}


.istockslink {
  color: white;
  text-transform: none;
}

.nav {
  display: flex;
  background-color: black;
  font-size: 2em;
  height: 3em;
}

.nav a{
display: block;
color: white;
text-decoration: none;
padding-left: 20px;
padding-right: 20px;
margin-right: 1em;
margin-left: 0.5em;
transition: 0.3s;
margin-top: auto;
margin-bottom: auto;
padding: 10px;
}

.nav a:hover {
  color: gray;
}

.activestocks{
  display: block;
  background-color: rgb(185, 185, 185);
  padding: 10px;
  text-decoration: none;
  color: rgb(227, 227, 227);
  width: 30%;
  margin: auto;
  transition: 0.3s;
  letter-spacing: -1px;
  border-radius: 1em;
  margin-top: 1em;
  height: 4.5em;
}

.activestocks:hover {
  color: rgb(113, 113, 113);
  background-color: rgb(179, 178, 178);
}

a:link, a:active, a:visited {
  color: white;
  text-decoration: none;
}

a:hover {
  color:rgb(227, 227, 227)
}